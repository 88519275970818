.product-details {
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .product-details h2 {
    font-size: 24px;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }
  
  .product-name {
    color: #333;
  }
  
  .product-brand {
    font-weight: bold;
  }
  
  .product-country {
    font-style: italic;
    color: #666;
  }
  
  .section-title {
    font-weight: bold;
    margin-top: 20px;
  }
  
  .nutritional-info-list, .categories-list, .data-quality-list {
    list-style: none;
    padding: 0;
  }
  
  .nutritional-info-list li {
    margin-bottom: 5px;
  }
  
  .categories-list li, .data-quality-list li {
    margin-bottom: 5px;
    color: #555;
  }
  
  .product-allergens, .product-traces {
    color: #e74c3c; /* Red color for allergens/traces */
  }
  