/* RecipeList.css */

.recipe-list-container {
    /* max-width: 900px; */
    margin: 20px auto;
    padding: 20px;
  }
  
  .recipe-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .recipe-table th, .recipe-table td {
    padding: 10px;
    text-align: left;
  }
  
  .recipe-table th {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
  }
  
  .even-row {
    background-color: #f2f2f2;
  }
  
  .odd-row {
    background-color: #f7f7f7;
  }
  
  .recipe-image {
    max-width: 80px;
    max-height: 80px;
    display: block;
    margin: 0 auto;
  }
  
  .recipe-title {
    color: #007bff;
    font-weight: bold;
    cursor: pointer; /* Add a pointer cursor for clickable titles */
  }
  
  /* Style the recipe pop-up */
  .recipe-popup {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
    text-align: center;
    padding-top: 20px;
  }
  
  .popup-content {
    background-color: #fff;
    border-radius: 5px;
    display: inline-block;
    text-align: left;
    padding: 20px;
    position: relative;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #555;
  }
  
  .popup-image {
    max-width: 100%;
    max-height: 300px;
    display: block;
    margin: 0 auto;
  }

  .recipe-instructions {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    font-size: 14px;
    line-height: 1.4;
  }

  .scrollable-popup {
    max-height: 800px; /* Adjust the height as needed */
    overflow-y: auto;
  }

  .nutritional-info ul {
    list-style: none;
    padding: 0;
  }
  
  .nutritional-info li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .nutrient-label {
    flex: 1;
    text-align: right;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .nutrient-values {
    flex: 2;
  }

  .recipe-cards {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
  }
  
  .recipe-card {
    width: 100%;
    max-height: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    transition: transform 0.2s;
    cursor: pointer;
    position: relative;
  }
  
  .recipe-card:hover {
    transform: scale(1.05);
  }
  
  .recipe-card .MuiCardMedia-root {
    object-fit:cover;
  }

  .view-recipe-button {
    text-align: center;
    padding: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    background-color: #007acc;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .view-recipe-button:hover {
    background-color: #00578e;
  }
  
  /* Media queries for responsive design */
  /* @media (min-width: 600px) {
    .recipe-card {
      width: calc(50% - 32px); 
    }
  }
  
  @media (min-width: 960px) {
    .recipe-card {
      width: calc(33.33% - 32px); 
    }
  }
   */
  
  /* Add any additional styling for the pop-up content */
  