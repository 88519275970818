/* contactus.css */

.contact-us {
    /* padding: 20px;
    text-align: center; */
    /* background-color: #f9f9f9; */
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-us-description {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .contact-form {
    max-width: 400px;
    margin: 0 auto;
    /* background-color: #fff; */
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  }
  
  .form-group {
    text-align: left;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  
  textarea {
    height: 150px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  