
 .header-container{
    margin-bottom: 100px;
} 
.app-header {
    background-color: white !important;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* padding: 20px 20px 0 0; */
    right: 0;
    /* width: 100%; */
    height: 60px;
    box-shadow: none !important;
  }

.app-header .typography{
  color: black !important;
}
  
  .logo-container {
    flex: 1;
    left: 0;
    display: flex;
    align-items: left;
    width: 200px;
    top: 0;
  }
  
  .logo-image {
    width: 100px;
    height: 100px;
  }

  .logo-video{
    width: 200px;
    height: 200px;
  }
  
  
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .navigation {
    display: flex;
    gap: 50px;
    font-size: 18px; 
  }
  
  .navigation a {
    text-decoration: none;
    color: #333;
  }
  
  