.about-us {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-title {
    font-size: 28px;
    color: #333;
    margin: 0 0 20px;
    text-align: center;
  }
  
  .about-us-description {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 15px;
    text-align: justify;
  }
  