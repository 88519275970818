/* IngredientInput.css */

.ingredient-input-container {
    /* max-width: 1000px; */
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  .ingredient-input-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .selected-ingredients {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    margin-top: 10px;
  }
  
  .ingredient-pill {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  .remove-button {
    background: none;
    border: none;
    font-size: 16px;
    color: #fff;
    margin-left: 5px;
    cursor: pointer;
  }
  
  .search-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }